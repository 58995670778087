/* eslint-disable react/function-component-definition */
import React from "react";
import FibreProsSection from "@/bit-components/fibre-pros-section";
import FaqsSection from "@/component/faqsSection";
import ServiceCard from "@/component/service-card";
import InteresCard from "@/component/interest-card";
import SafeBoxCard from "@/component/safebox-card";
import RecomCharacteristics from "@/component/recom-characteristics";
import RecomCharacteristicsWithImage from "@/component/recom-characteristics-with-image";
import CotizadorMPA from "@/component/new-cotizador-mpa";
import InformationModule from "@/component/information-module";
import Advantages from "@/component/advantages-section";
import CardListFlyout from "@/component/card-list-flyout";

/**
 * @name Layout
 * @description Connectivity page layout
 * @param {any} props
 * @returns {React.FC}
 */
export default function Layout(props) {
  const moduleOrdered = props.ORDEN_MODULOS;
  moduleOrdered?.sort(
    (a, b) => parseInt(a.moduleOrder, 10) - parseInt(b.moduleOrder, 10)
  );

  const switchPrint = (item) => {
    const nameModule = item.nameModule.split("-|-")[0];
    if (!item.hidden) {
      switch (nameModule) {
        case "LISTADO_CARACTERISTICAS_ICONO_TITULO_DESCRIPCION":
          if (props.LISTADO_PASOS_ICONO_TITULO_DESCRIPCION) {
            return (
              <FibreProsSection
                sectionLeft={props[item.nameModule]}
                sectionRight={props.LISTADO_PASOS_ICONO_TITULO_DESCRIPCION}
                simplePhone={props.MODULO_SIMPLEPHONE}
              />
            );
          }
          if (props.LISTADO_CARACTERISTICAS_ICONO_TITULO_DESCRIPCION_2) {
            return (
              <FibreProsSection
                sectionLeft={props[item.nameModule]}
                sectionRight={
                  props.LISTADO_CARACTERISTICAS_ICONO_TITULO_DESCRIPCION_2
                }
                simplePhone={props.MODULO_SIMPLEPHONE}
              />
            );
          }
          return null;
        case "LISTADO_PASOS_ICONO_TITULO_DESCRIPCION":
          if (props.LISTADO_PASOS_ICONO_TITULO_DESCRIPCION_2) {
            return (
              <FibreProsSection
                sectionLeft={props[item.nameModule]}
                sectionRight={props.LISTADO_PASOS_ICONO_TITULO_DESCRIPCION_2}
                simplePhone={props.MODULO_SIMPLEPHONE}
              />
            );
          }
          return null;
        case "MODULO_CARDS":
          return (
            <div>
              <ServiceCard orionInfo={props[item.nameModule]} />
            </div>
          );
        case "COMPONENT_CARD_LIST":
          return <CardListFlyout {...props[item.nameModule]} />;
        case "MODULO_RECOMENDACION":
          return <RecomCharacteristics {...props[item.nameModule]} />;
        case "MODULO_VENTA_CRUZADA_OTROS_SERVICIOS":
          return <InteresCard {...props[item.nameModule]} />;
        case "MODULO_CARDS_VENTA_CRUZADA":
          return <SafeBoxCard {...props[item.nameModule]} />;
        case "COMPONENT_TYPE":
          if (props[item.nameModule].componentId === "cotizador") {
            return <CotizadorMPA />;
          }
          return null;
        case "MODULO_DESCRIPTION_PICTURE":
          return <InformationModule {...props[item.nameModule]} />;
        case "MODULO_ECOSYSTEM":
          return (
            <RecomCharacteristicsWithImage
              {...props[item.nameModule]}
              recom={{ ...props[item.nameModule] }}
            />
          );
        case "ICON_ECOSYSTEM_PROSEGUR":
          return <Advantages {...props[item.nameModule]} showMobile />;
        case "FAQS_PREGUNTAS_FRECUENTES":
          return <FaqsSection {...props[item.nameModule]} />;
        default:
          return null;
      }
    }
  };
  return <>{moduleOrdered?.map((module) => switchPrint(module))}</>;
}
