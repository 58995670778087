import React from "react";
import Text from "@/bit/components/components.text";
import Image from "@/bit/components/components.c-image";
import RecomCharacteristics from "@/component/recom-characteristics";

const RecomCharacteristicsWithImage = (props) => {
  return (
    <div className="recom-with-image">
      <div className="container">
        {props["Text-1"] && props["Text-1"].text && (
          <div className="title">
            <Text
              {...props["Text-1"]}
              kind="h"
              level="2"
              size="t1"
              cyData="RecomCharacteristics Title"
            />
          </div>
        )}
      </div>
      <div className="recom-container">
        <div className="wrapper-image">
          <Image
            {...{
              insideContainer: false,
              alt: "Televisión",
              src: props.Image,
              srcWebp: "",
              fill: true,
              priority: true,
              objectFit: "cover",
              compressionByResize: false,
            }}
          />
        </div>
        <RecomCharacteristics {...{ ...props.recom, "Text-1": null }} />
      </div>
      <style jsx>{`
        .recom-with-image {
          .title {
            margin: 36px 0 24px 0;
          }
          .recom-container {
            padding: 80px 0px;
            position: relative;
            .wrapper-image {
              top: 0px;
              position: absolute;
              width: 100%;
              height: 100%;
            }
            :global(.blur-right) {
              background: none !important;
            }
            :global(.blur-left) {
              background: none !important;
            }
          }
          :global(.separator) {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default RecomCharacteristicsWithImage;
